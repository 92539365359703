.filter-list {
  margin: auto;
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
}

.filter-list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.filter-list li {
  float: left;
  padding: 10px;
}

.filter-list li span {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  padding: 10px 16px;
  color: #27272d;
  background-color: white;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 150ms ease-in-out, color 150ms ease-in-out,
    background-color 150ms ease-in-out;
}

.filter-list li span:hover {
  background-color: #111111;
  color: #deccac;
  transform: scale(105%);
}

.active {
  background-color: #111111;
  color: #deccac;
  border: 3px solid white;
}

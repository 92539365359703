.about-grid-container {
  display: grid;
  gap: 20px;
  grid-template-columns: auto;
  padding: 10px;
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
  font: 30px;
}

.about-grid-item {
  margin: 0 auto;
  position: relative;
  background-color: #deccac;
  border: 2px solid rgba(0, 0, 0, 0.8);
  border-radius: 12px;
  overflow: hidden;
  min-height: 80px;
  max-height: 600px;
  width: 85%;
  transition: box-shadow ease 0.25s, transform ease 0.25s,
    max-height 0.25s ease-in;
  box-shadow: -5px 5px 2px rgba(0, 0, 0, 0.15);
}

.about-grid-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.about-grid-item__top {
  display: flex;
  height: 80px;
  justify-content: center;
}

.about-grid-item__top .content {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  height: 80px;
  text-align: center;
  background: linear-gradient(#deccac, transparent);
  color: #27272d;
  font-weight: bold;
}

.about-grid-item__top .title {
  font-size: 25px;
  font-weight: bold;
}

.about-grid-item__top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-grid-item__body {
  color: black;
  margin-top: 5px;
  margin-bottom: 50px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 20px;
  font-weight: bold;
}

.about-grid-item__body .title{
  text-align: left;
  margin-top: 20px;
}

.about-grid-item__body .date{
  text-align: right;
  margin-top: 18px;
  font-size: 18px;
  margin-bottom: -15px;
}

.about-grid-item__body .description{
  text-align: left;
  margin-top: 5px;
  font-size: 18px;
}

.label {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
}

.about-grid-item__bot {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  margin: auto;
}

.about-tech-grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  margin-top: 10px;
  margin-bottom: 25px;
  justify-content: center;
}

.about-tech-grid-container__img {
  width: 90px;
  height: 50px;
  object-fit: contain;
  display: block;
  margin: 0px 0px;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

@media only screen and (max-width: 2560px) and (min-width: 1980px) {
  .about-grid-container {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 360px) {
}

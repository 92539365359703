.main, .main-noborder {
  box-sizing: border-box;
  border-top: 15px solid black;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #27272D;
}

.main {
  border: 0;
  overflow-x: hidden;
}

.main-content {
  box-sizing: border-box;
  position: absolute;
  height: auto;
  width: 100%;
  background-color: #27272D;
}

.main-light {
  height: 100%;
  width: 100%;
  background-image: radial-gradient(#deccac, transparent);
  border-radius: 25px;
  filter: blur(10px);
  z-index: 1;
}

.header {
  color: #27272D;
  z-index: 2;
  margin: 30px;
  font-weight: bold;
}

.description {
  color: #27272D;
  z-index: 2;
  font-size: 22px;
}

body {
  background-color: #27272D;
  margin: 0;
  padding: 0;
}

.center-ver {
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;
}

.center-hor {
  left: 50%;
  transform: translate(-50%, 0%);
  position: absolute;
}

.center-ver-hor {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

@media only screen and (max-width: 412px) {
  .header{
    font-size: 18px;
  }
  .description{
    font-size: 12px;
  }
}

@media only screen and (max-width: 360px) {
  .header{
    font-size: 18px;
  }
  .description{
    font-size: 12px;
  }
}

@media only screen and (max-width: 320px) {
  .header{
    font-size: 18px;
  }
  .description{
    font-size: 12px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2.625) {
  .header{
    font-size: 18px;
  }
  .description{
    font-size: 12px;
  }
}

.App {
  text-align: center;
  background-color: #27272D
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



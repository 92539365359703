.rest {
  position: relative;
  margin-top: 350px;
}

.buttons-grid {
  margin: auto;
  margin-bottom: 100px;
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 60px;
  grid-column-gap: 0px;
  grid-row-gap: 20px;
  justify-items: center;
  filter: url(#round);
}

@media only screen and (max-width: 1980px) {
  .rest {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 1440px) {
  .rest {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 420px) {
  .rest {
    margin-top: -20px;
  }

  .buttons-grid {
    grid-template-columns: 1fr;
    grid-row-gap: 60px;
  }
}

@media only screen and (max-width: 360px) {
  .rest {
    margin-top: -20px;
  }

  .buttons-grid {
    grid-template-columns: 1fr;
    grid-row-gap: 60px;
  }
}

@media only screen and (max-width: 320px) {
  .rest {
    margin-top: -20px;
  }

  .buttons-grid {
    grid-template-columns: 1fr;
    grid-row-gap: 60px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2.625) {
  .rest {
    margin-top: -20px;
  }

  .buttons-grid {
    grid-template-columns: 1fr;
    grid-row-gap: 80px;
  }
}

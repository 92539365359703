.button {
  width: auto;
  position: relative;
  font-family: "Encode Sans", sans-serif;
  color: #27272d;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  font-size: 35px;
  cursor: pointer;
  z-index: 9;
  transition: border 650ms ease-in;
}
.button:hover + .bottom-light {
  filter: blur(0px);
}

p {
  color: #27272d;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

/* Fade in */
p:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0px;
  left: 0;
  background-color: #27272d;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
p:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.bottom-light {
  margin-top: -180px;
  position: relative;
  height: 260px;
  width: 260px;
  background-image: radial-gradient(#deccac, transparent);
  z-index: 8;
  border-radius: 50%;
  filter: blur(19px);
  transition: filter 250ms ease-out;
  opacity: 1;
}

.spotlight-right {
  animation: spotlightInRight 1 1600ms cubic-bezier(0, 0, 0, 1.13),
    spotlight infinite 12s linear;
  animation-direction: alternate;
  animation-delay: 0ms, 1800ms;
}

.spotlight-left {
  animation: spotlightInLeft 1 1400ms cubic-bezier(0, 0, 0, 1.63),
    spotlight infinite 10s linear;
  animation-direction: alternate;
  animation-delay: 0ms, 1800ms;
}

@keyframes spotlightInRight {
  from {
    transform: translate(900px, 50px);
  }

  to {
    transform: translate(0px, 0px);
  }
}

@keyframes spotlightInLeft {
  from {
    transform: translate(-900px, 50px);
  }

  to {
    transform: translate(0px, 0px);
  }
}

@keyframes spotlight {
  from {
    transform: rotate(0deg) translate(0px, 0px);
  }

  to {
    transform: rotate(360deg) translate(20px, 0px);
  }
}

@media only screen and (max-width: 1980px) {
  .button {
    font-size: 32px;
  }

  .bottom-light {
    margin-top: -160px;
    height: 220px;
    width: 220px;
  }
}

@media only screen and (max-width: 1980px) {
  .button {
    font-size: 32px;
  }

  .bottom-light {
    margin-top: -160px;
    height: 200px;
    width: 200px;
  }
}

@media only screen and (max-width: 412px) {
  .button {
    font-size: 18px;
  }

  .bottom-light {
    margin-top: -85px;
    height: 100px;
    width: 100px;
  }
}

@media only screen and (max-width: 360px) {
  .button {
    font-size: 18px;
  }

  .bottom-light {
    margin-top: -85px;
    height: 100px;
    width: 100px;
  }
}

@media only screen and (max-width: 320px) {
  .button {
    font-size: 18px;
  }

  .bottom-light {
    margin-top: -85px;
    height: 100px;
    width: 100px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2.625) {
  .button {
    font-size: 18px;
  }

  .bottom-light {
    margin-top: -85px;
    height: 100px;
    width: 100px;
  }
}
.banner {
  text-align: center;
  height: 500px;
  position: relative;
  white-space: pre-line;
}

.banner-title {
  color: #deccac;
  font-family: "Encode Sans", sans-serif;
  font-size: 40px;
  height: 30%;
  top: 10%;
  mix-blend-mode: difference;
  z-index: 10;
}

.banner-title-hidden {
  color: #27272d;
  font-family: "Encode Sans", sans-serif;
  font-size: 50px;
  height: 30%;
  top: 70%;
  z-index: 9;
}

.lamp-cable {
  position: relative;
  width: 10px;
  height: 60px;
  background: black;
  z-index: 8;
}

.lamp-joint {
  position: relative;
  width: 7px;
  height: 7px;
  background: white;
  border: 2px solid black;
  border-radius: 50%;
  z-index: 8;
}

.lamp {
  position: relative;
  width: 90px;
  height: 25px;
  background: black;
  border-radius: 150px 150px 0 0;
  transform-origin: 50% 0%;
  z-index: 7;
}

.lamp-elipse {
  position: absolute;
  width: 200px;
  height: 8px;
  background: white;
  clip-path: ellipse(20% 50%);
  transform: translate(-50%,200%);
  z-index: 9;
}

.light {
  top: 20px;
  position: relative;
  width: 1300px;
  height: 500px;
  background: linear-gradient(#deccac, rgba(255, 0, 0, 0));
  clip-path: polygon(20% 100%, 47% 0%, 53% 0%, 80% 100%);
  z-index: 8;
}

.major-light {
  top: 20px;
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background: linear-gradient(#deccac, rgba(255, 0, 0, 0));
  clip-path: polygon(0% 100%, 48.5% 0%, 51.5% 0%, 100% 100%);
  z-index: 8;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.major-light-container{
  margin-top: 100px;
  width: 40%;
  height: auto;
  color: #deccac;
  font-family: "Encode Sans", sans-serif;
  font-size: 40px;
}

@media only screen and (max-width: 1980px) {
  .banner-title-hidden{
    font-size: 40px;
  }

  .light {
      height: 420px;
  }

  .major-light {
    clip-path: polygon(0% 100%, 48% 0%, 52% 0%, 100% 100%);
  }
}

@media only screen and (max-width: 1440px) {
  .lamp-cable {
    width: 10px;
    height: 40px;
  }

  .banner-title-hidden{
    font-size: 40px;
    top: 55%;
  }

  .light {
      height: 350px;
  }

  .major-light {
    width: 140vw;
    clip-path: polygon(0% 100%, 48% 0%, 52% 0%, 100% 100%);
  }
}

@media only screen and (max-width: 412px) {
  .banner {
    height: 400px;
  }

  .lamp-cable {
    width: 5px;
    height: 10px;
  }

  .lamp-joint {
    width: 4px;
    height: 4px;
  }

  .lamp {
    width: 70px;
    height: 20px;
    border-radius: 150px 150px 0 0;
    transform-origin: 50% 0%;
  }

  .lamp-elipse {
    width: 170px;
    height: 7px;
    clip-path: ellipse(20% 50%);
    transform: translate(-50%,200%);
  }

  .light {
    top: 15px;
    width: 800px;
    height: 300px;
  }

  .banner-title-hidden{
    font-size: 22px;
    top: 50%;
  }

  .major-light {
    clip-path: polygon(0% 100%, 48% 0%, 52% 0%, 100% 100%);
  }
}

@media only screen and (max-width: 360px) {
  .banner {
    height: 400px;
  }

  .lamp-cable {
    width: 5px;
    height: 20px;
  }

  .lamp-joint {
    width: 4px;
    height: 4px;
  }

  .lamp {
    width: 70px;
    height: 20px;
    border-radius: 150px 150px 0 0;
    transform-origin: 50% 0%;
  }

  .lamp-elipse {
    width: 170px;
    height: 7px;
    clip-path: ellipse(20% 50%);
    transform: translate(-50%,200%);
  }

  .light {
    top: 15px;
    width: 800px;
    height: 300px;
  }

  .banner-title-hidden{
    font-size: 22px;
    top: 50%;
  }

  .major-light {
    width: 450vw;
    clip-path: polygon(0% 100%, 48% 0%, 52% 0%, 100% 100%);
  }

  .major-light-container {
    font-size: 30px;
  }
}

@media only screen and (max-width: 320px) {
  .banner {
    height: 400px;
  }

  .lamp-cable {
    width: 5px;
    height: 20px;
  }

  .lamp-joint {
    width: 4px;
    height: 4px;
  }

  .lamp {
    width: 70px;
    height: 20px;
    border-radius: 150px 150px 0 0;
    transform-origin: 50% 0%;
  }

  .lamp-elipse {
    width: 170px;
    height: 7px;
    clip-path: ellipse(20% 50%);
    transform: translate(-50%,200%);
  }

  .light {
    top: 15px;
    width: 800px;
    height: 300px;
  }

  .banner-title-hidden{
    font-size: 22px;
    top: 50%;
  }

  .major-light {
    clip-path: polygon(0% 100%, 48% 0%, 52% 0%, 100% 100%);
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2.625) {
  .banner {
    height: 400px;
  }

  .lamp-cable {
    width: 5px;
    height: 10px;
  }

  .lamp-joint {
    width: 4px;
    height: 4px;
  }

  .lamp {
    width: 70px;
    height: 20px;
    border-radius: 150px 150px 0 0;
    transform-origin: 50% 0%;
  }

  .lamp-elipse {
    width: 170px;
    height: 7px;
    clip-path: ellipse(20% 50%);
    transform: translate(-50%,200%);
  }

  .light {
    top: 15px;
    width: 800px;
    height: 300px;
  }

  .banner-title-hidden{
    font-size: 25px;
    top: 43%;
  }

  .major-light {
    clip-path: polygon(0% 100%, 48% 0%, 52% 0%, 100% 100%);
    width: 900;
    min-height: 100vh;
  }
}

.flt_svg {
  visibility: hidden;
  position: absolute;
  width: 0px;
  height: 0px;
}

.right {
  right: 0;
}

